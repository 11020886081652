import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import threadsData from '../../content/page-data/threads.json'

const ThreadsPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="About Me" />
            {threadsData.map((data) => {
                return (<div><h4>{data.topic}</h4><ol style={{ listStyle: 'inside', listStyleType: 'decimal',
    paddingLeft: 0}}>{
                    data.threads.map((thread) => {
                        return (<li><a href={thread.url}>{thread.description}</a></li>)
                    })
                }</ol></div>)
            })}
        </Layout>
    )
}

export default ThreadsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
